.special
{
  width: 100%;
  height: 100%;
  overflow: scroll;
  box-sizing: border-box;
  padding: 24px;

  .container
  {
    margin: 0;
    padding: 0;
    padding-bottom: 50px;
  }

  p
  {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  h2
  {
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  .small {
    font-size: 0.8rem;
  }
}
